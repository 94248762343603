import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { css } from "@emotion/react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Observer, {
  useIntersectionObserver,
} from "@researchgate/react-intersection-observer";
import { Spinner } from "reactstrap";
import { logEvent } from "../api";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";
import { Link } from "gatsby-plugin-react-i18next";
import { getI18nURLPrefix } from "../utils/localStorage";
import { LazyLoadImage } from "react-lazy-load-image-component";

const NextArrow = ({ onClick }) => (
  <button
    type="button"
    className="movie-carousel-next"
    onClick={onClick}
  ></button>
);
const PrevArrow = ({ onClick }) => (
  <button
    type="button"
    className="movie-carousel-prev"
    onClick={onClick}
  ></button>
);

const randomBool = () => Math.random() >= 0.5;

export default class Row extends React.Component {
  state = {
    isLoading: false,
    disableClickEvents: false,
    lastMoviesLength: 9999,
  };

  componentWillMount() {
    if (typeof window !== "undefined") {
      require("intersection-observer");
    }
  }

  handleNextPageLoad = async (event, unobserve) => {
    const moviesLength = this.props.movies.length;
    const lastMoviesLength = this.state.lastMoviesLength;
    // alert(moviesLength+" "+lastMoviesLength)
    // this.setState[{lastMoviesLength}]

    if (moviesLength === lastMoviesLength) return;
    if (
      event.isIntersecting &&
      !this.state.isLoading &&
      event.intersectionRatio > 0.01 &&
      moviesLength < 100 &&
      this.props.querystring
    ) {
      this.setState({ isLoading: true, lastMoviesLength: moviesLength });
      await this.props.fetchNextPage(this.props.querystring, moviesLength);
      unobserve();
      this.setState({ isLoading: false });
    }
  };

  handleInterestIndicator = async (event, rowblock, querystring) => {
    // Disable rowblockclick logging
    return;
    const { disableClickEvents } = this.state;
    if (event === "touch" && !disableClickEvents)
      this.setState({ disableClickEvents: true });
    if (event === "click" && disableClickEvents) return;

    logEvent(
      "rowblockclick",
      "row",
      querystring, //rowblock,
      querystring,
      null
    );
  };

  handleClick = async (itemId) => {
    // Stop sending clicks
    return;
    this.props.sendImpressions(itemId);
  };

  render() {
    const settings = {
      className: "col-12",
      slidesToShow: 7,
      slidesToScroll: 7,
      autoplay: false,
      dots: false,
      infinite: false,
      centerMode: false,
      variableWidth: true,
      variableHeight: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      centerPadding: "0px",
      dotsClass: "slick-dots d-none d-sm-block",
      responsive: [
        { breakpoint: 430, settings: "unslick" },
        // { breakpoint: 430,
        //     settings: { slidesToShow: 3, slidesToScroll: 2, }
        // },
        { breakpoint: 540, settings: { slidesToShow: 3, slidesToScroll: 3 } },
        { breakpoint: 940, settings: { slidesToShow: 5, slidesToScroll: 4 } },
        { breakpoint: 1120, settings: { slidesToShow: 6, slidesToScroll: 5 } },
        { breakpoint: 1300, settings: { slidesToShow: 7, slidesToScroll: 6 } },
      ],
    };

    let items = [];

    // Turnaround for hook intersection observer on build time
    if (typeof window === "undefined") return "";

    if (!this.props.movies || this.props.movies.length < 1) {
      for (let i = 0; i < 20; i++) {
        // Placeholders
        items.push(<Item placeholder key={i} />);
      }
    } else {
      items = this.props.movies.map((movie, index) => {
        const m = movie.slug ? movie : movie.node;
        if (index > 1 && index % 6 === 0) {
          return (
            <>
              <Observer
                onChange={this.handleNextPageLoad}
                className="m-0 p-0"
                rootMargin="0px 0px 0px 170px"
              >
                <span className="m-0 p-0"></span>
              </Observer>
              <Item
                handleClick={this.handleClick}
                addImpressionId={this.props.addImpressionId}
                key={m.id}
                id={m.id}
                title={m.title}
                thumb={m.poster_thumb}
                slug={m.slug}
              />
            </>
          );
        }
        return (
          <Item
            handleClick={this.handleClick}
            addImpressionId={this.props.addImpressionId}
            key={m.id}
            id={m.id}
            title={m.title}
            thumb={m.poster_thumb}
            slug={m.slug}
          />
        );
      });
      // const observer = (
      //   <Observer onChange={this.handleNextPageLoad} rootMargin="0% 25%">
      //     <span>
      //     </span>
      //   </Observer>
      // )
      // items.splice(items.length - 5, 0, observer)

      // Add placeholders
      // while (items.length < 40 && this.props.querystring && this.state.isLoading) {
      //   if (items.length % 7 === 0) {
      //     items.push(
      //       <>
      //         <Observer onChange={this.handleNextPageLoad} className="m-0 p-0" rootMargin="0% 5%">
      //           <span className="m-0 p-0"></span>
      //         </Observer>
      //         <Item placeholder/>
      //       </>
      //     )
      //   } else{
      //     items.push(<Item placeholder/>)
      //   }
      // }
      if (this.props.querystring && this.state.isLoading && items.length < 40) {
        items.push(
          <Observer
            onChange={this.handleNextPageLoad}
            className="m-0 p-0"
            rootMargin="0px 0px 0px 170px"
          >
            <span className="m-0 p-0"></span>
          </Observer>
        );
        for (let i = 0; i < 5; i++) {
          items.push(<Item placeholder />);
        }
      }

      // Add observers

      // items.push(
      //     <div className="poster-wrapper mr-0">
      //       <div className="poster-image text-center h-100 m-auto">
      //         <div className="h-100 d-flex justify-content-start ml-2">
      //           {this.state.isLoading && <Spinner className="my-auto" size="sm"/>}
      //         </div>
      //       </div>
      //     </div>
      // )
    }

    const { rowblock, querystring } = this.props;

    return (
      <div
        className="movie-carousel"
        onClick={() =>
          this.handleInterestIndicator("click", rowblock, querystring)
        }
        onTouchEnd={() =>
          this.handleInterestIndicator("touch", rowblock, querystring)
        }
      >
        <Slider {...settings} className="">
          {items}
        </Slider>
      </div>
    );
  }
}

const Item = (props) => {
  // const [recordedTimeout, setRecordedTimeout] = useState()

  // const handleChange = async (entry, unobserve) => {
  //   if (entry.isIntersecting && entry.intersectionRatio >= 0.5) {
  //     setRecordedTimeout( setTimeout(() => {
  //       props.addImpressionId(props.id)
  //       unobserve();
  //     }, 1000))
  //     return
  //   }
  //   clearTimeout(recordedTimeout);
  // };

  // const [ref] =  useIntersectionObserver(handleChange, { threshold: 0.8 });
  const [prefix, setPrefix] = useState("/");
  useEffect(async () => {
    let getPrefix = await getI18nURLPrefix();
    setPrefix(getPrefix.slice(0, -1));
  }, []);

  if (props.placeholder) {
    return (
      // <div className="poster-wrapper mr-0">
      //   <div className="poster-image bg-secondary text-center d-flex">
      //     <Spinner className="m-auto text-center text-light" size="sm"/>
      //   </div>
      //   <div className="poster-title text-dark"></div>
      // </div>
      <div
        key={props.key}
        className="poster-wrapper mt-2 mb-3 mr-1 shadow-sm overflow-hidden bg-white rounded"
      >
        <Link to={props.slug}>
          <div className="poster-image bg-light text-center d-flex">
            <Spinner className="m-auto text-center text-secondary" size="sm" />
          </div>
          <div className="poster-title text-dark p-1"> {props.title} </div>
        </Link>
      </div>
    );
  }

  return (
    <div
      key={props.key}
      className="poster-wrapper mt-2 mb-3 mr-1 shadow-sm overflow-hidden bg-white rounded"
    >
      {/* <Link
        to={props.slug}
        target="_blank"
        onClick={() => props.handleClick(props.id)}
      > */}
      <a
        href={`${prefix}${props.slug}`}
        // onClick={() => props.handleClick(props.id)}
      >
        <div className="poster-image bg-light">
          <LazyLoadImage
            className="movie-poster"
            title={props.title}
            src={props.thumb && props.thumb.url}
            height={props.thumb && props.thumb.y}
            width={props.thumb && props.thumb.x}
          />
        </div>
        <div className="poster-title text-dark p-1">
          <span>{props.title}</span>
        </div>
        {/* </Link> */}
      </a>
    </div>
  );
};

export class RowSM extends React.Component {
  render() {
    const settings = {
      className: "",
      slidesToShow: 3,
      slidesToScroll: 3,
      autoplay: false,
      dots: false,
      infinite: false,
      centerMode: false,
      variableWidth: true,
      variableHeight: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      centerPadding: "0px",
      responsive: [
        { breakpoint: 430, settings: { slidesToShow: 2, slidesToScroll: 1 } },
        // { breakpoint: 540,
        //     settings: { slidesToShow: 3, slidesToScroll: 3, }
        // },
        // { breakpoint: 940,
        //     settings: { slidesToShow: 3, slidesToScroll: 3, }
        // },
        // { breakpoint: 1120,
        //     settings: { slidesToShow: 3, slidesToScroll: 3, }
        // },
        // { breakpoint: 1300,
        //   settings:
        //     { slidesToShow: 3, slidesToScroll: 3, }
        // }
      ],
    };

    if (!this.props.movies || this.props.movies.length < 1) {
      return "";
    } else {
      const items = this.props.movies.map((movie) => {
        const m = movie.slug ? movie : movie.node;
        return (
          <ItemSM
            key={m.slug}
            id={m.id}
            title={m.title}
            img={m.poster}
            thumb={m.poster_thumb}
            slug={m.slug}
          />
        );
      });

      return (
        <div className="movie-carousel ">
          <Slider {...settings} className="m-0 p-0">
            {items}
          </Slider>
        </div>
      );
    }
  }
}

export const AmazonProductRowMobileSM = (props) => {
  if (!props.products) return "";
  if (props.products.length < 1) return "";
  const items = props.products.map((product) => {
    return (
      <div>
        <div
          dangerouslySetInnerHTML={{ __html: product.iframe }}
          className="mobile-poster-wrapper"
        ></div>
        <a
          href={product.url}
          target="_blank"
          rel="nofollow"
          className="mobile-poster-wrapper cursor-pointer"
          style={{
            display: "block",
            position: "relative",
            width: 120,
            height: 240,
            marginTop: "-242px",
            "z-index": 2,
          }}
          onClick={() => {
            logEvent(
              "click",
              props.slug,
              "AffiliateProduct",
              props.translationId,
              product.id
            );
            trackCustomEvent({
              category: "Amazon Affiliate Link Button",
              action: "Click",
              label: props.slug,
            });
          }}
        ></a>
      </div>
    );
  });
  return <div className="movie-carousel y-scroll">{items}</div>;
};

export const RowMobileSM = (props) => {
  if (!props.movies) return "";
  if (props.movies.length < 1) return "";
  const items = props.movies.map((movie) => {
    const m = movie.slug ? movie : movie.node;
    return (
      <ItemSM
        key={m.id}
        id={m.id}
        title={m.title}
        img={m.poster}
        thumb={m.poster_thumb}
        slug={m.slug}
        className="mobile-poster-wrapper"
      />
    );
  });
  return <div className="movie-carousel y-scroll">{items}</div>;
};

export const RowMobile = (props) => {
  let items = [];
  if (!props.movies || props.movies.length < 1) {
    for (let i = 0; i < 20; i++) {
      items.push(
        <span>
          <Item placeholder />
        </span>
      );
    }
  } else {
    items = props.movies.map((movie) => {
      const m = movie.slug ? movie : movie.node;
      return (
        <Item
          key={m.id}
          id={m.id}
          title={m.title}
          img={m.poster}
          thumb={m.poster_thumb}
          slug={m.slug}
        />
      );
    });
  }

  return <div className="movie-carousel y-scroll">{items}</div>;
};

const ItemSM = (props) => {
  if (props.placeholder) {
    return (
      <div css={wrapperSM} className="mx-0">
        <div css={itemSM} className="m-5 movie-poster bg-secondary">
          <div css={moviePosterSM} className="movie-poster bg-secondary"></div>
        </div>
      </div>
    );
  }
  return (
    <div
      css={wrapperSM}
      className="ml-2 mt-2 mb-3 shadow-sm rounded-sm overflow-hidden"
    >
      <Link to={`/t/${props.slug}`}>
        <div css={itemSM}>
          <img
            loading="lazy"
            css={moviePosterSM}
            src={props.thumb && props.thumb.url}
            height={props.thumb && props.thumb.y}
            width={props.thumb && props.thumb.x}
          />
        </div>
        <div className="poster-title text-dark p-1" style={{ height: 46 }}>
          {" "}
          {props.title}{" "}
        </div>
      </Link>
    </div>
  );
};

const itemSM = css({
  width: 142.8,
  height: 189,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "50% 50%",
  borderRadius: 5,
});

const moviePosterSM = css({
  width: 142.8,
  height: 189,
  // borderRadius: 5,
});

const wrapperSM = css({
  display: "flex",
  flexDirection: "column",
  textDecoration: "none",
  maxWidth: 142.8,
  minWidth: 142.8,
  width: 142.8,
  fontSize: "0.8rem",
});
